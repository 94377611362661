import React from "react"
import GlobalStyles from "../styles/GlobalSlyles"
import "normalize.css"

function Wrap({ children }) {
  return (
    <>
      <GlobalStyles />
      {children}
    </>
  )
}

export default Wrap
