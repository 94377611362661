import React, { useState } from "react"

const Context = React.createContext()

function ContextProvider({ children }) {
  const [videoSrc, setVideoSrc] = useState("")
  const [modalOpen, setModalOpen] = useState(false)

  return (
    <Context.Provider
      value={{
        videoSrc,
        setVideoSrc,
        modalOpen,
        setModalOpen,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export { ContextProvider, Context }
