import React from "react"
import Wrap from "./src/components/Wrap"
import { ContextProvider } from "./src/context/Context"
import "./src/styles/Typography2.css"
import "./src/styles/openSans.css"

export function wrapPageElement({ element, props }) {
  return <Wrap {...props}>{element}</Wrap>
}

export function wrapRootElement({ element }) {
  return <ContextProvider>{element}</ContextProvider>
}

//modal

export const onPrefetchPathname = ({ loadPage }) => {
  if (window.indexPageData === undefined) {
    loadPage("/home").then(result => {
      window.indexPageData = result

      if (window.setIndexPageData) window.setIndexPageData()
    })
  }
}

// https://github.com/gatsbyjs/gatsby/issues/7454#issuecomment-425403812
export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  return getSavedScrollPosition(location)
}
