import { createGlobalStyle } from "styled-components"
import stripes from "../assets/images/stripes.svg"

const GlobalStyles = createGlobalStyle`
:root {
  --clr-primary: #00abbf;
  --clr-background: #fff;
  --clr-black: #222;
  --clr-white: #fff;
  --clr-red: #df3a3a;

  --scrollbarBG: #d1dcde;
  --thumbBG: #8dc3c9;

  --border: 1px solid #222;
  --border-gray: 1px solid #c4c4c4;
}


/*RESET*/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  scroll-behavior: smooth;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

html {
    box-sizing: border-box;
}

body,
*::before,
*::after {
  background: var(--clr-background);
  -webkit-animation: fadein 2s; /* Safari and Chrome */
  -moz-animation: fadein 2s; /* Firefox */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera */
  animation: fadein 2s;
}

body, html {
  height: 100%;
  width: 100%;
  margin: 0;
}

@keyframes fadein {
0% {opacity:0;}
100% {opacity:1;}
}

@-moz-keyframes fadein {
0% {opacity:0;}
100% {opacity:1;}
}

@-webkit-keyframes fadein {
0% {opacity:0;}
100% {opacity:1;}
}

@-o-keyframes fadein {
0% {opacity:0;}
100% {opacity:1;}
}

@-ms-keyframes fadein {
0% {opacity:0;}
100% {opacity:1;}
}

ul {
  list-style-type: none;
}
  .nowrap {
    white-space: nowrap;
  }

  /* Scrollbar Styles */
  /* body::-webkit-scrollbar {
    width: 12px;
  }
  html {
    scrollbar-width: thin;
    scrollbar-color: var(--scrollbarBG) var(--thumbBG);
  }
  body::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
  }
  body::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG) ;
    border-radius: 6px;
    ${"" /* border: 3px solid var(--scrollbarBG); */}
  } */

  /* Stripes */
    hr {
    border: 0;
    height: 70px;
    background-image: url(${stripes});
    background-size: 2000px;
  }
`

export default GlobalStyles
